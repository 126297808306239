import _sanitizeHtml from 'sanitize-html';

export const sanitizeHtml = (html) => {
  const parsed = _sanitizeHtml(html, {
    allowedTags: [
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'p',
      'span',
      'ol',
      'ul',
      'li',
      'strong',
      'em',
      'u',
      's',
      'br',
    ],
    allowedAttributes: {
      p: ['style'],
      li: ['style'],
      span: ['style'],
      strong: ['style'],
      em: ['style'],
      u: ['style'],
      s: ['style'],
      br: ['style'],
    },
    allowedStyles: {
      color: [
        /^#(0x)?[0-9a-f]+$/i,
        /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
      ],
      'text-align': [/^left$/, /^right$/, /^center$/, /^justify$/],
      'font-size': [/^\d+(?:px|em|%)$/],
      'font-family': [/^sans-serif$/, /^verdana$/],
      'background-color': [
        /^#(0x)?[0-9a-f]+$/i,
        /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
      ],
    },
  });

  const regex = /style="(?<style>.*?)">/gm;

  let output = parsed;
  let match;

  while ((match = regex.exec(output))) {
    const { style } = match.groups;

    if (match?.length > 1 && style) {
      let parsedString = style;
      parsedString = parsedString?.replaceAll(';', '; ');
      parsedString = parsedString?.replaceAll(':', ': ');
      parsedString = parsedString + ';';

      output = output.replace(match[1], parsedString);
    }
  }

  return output;
};
