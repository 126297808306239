import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

const DetailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  gap: 16px;
`;

const TitleCampaignContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const TitleCampaignAside = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const LinkContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
  background-color: var(--color-neutral-pure);
`;

const Image = styled.img`
  max-height: 300px;
  border-radius: 8px;
`;

const SkeletonContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 50px;

  > span {
    margin-bottom: 10px;
  }
`;

const BackButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :hover {
    color: var(--color-neutral-light1);
  }
`;

const BackButtonText = styled(Typography)`
  color: var(--color-neutral-dark1);
  margin-left: 2px;
`;

const Container = styled.div<{ visible?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  width: 583px;
  max-width: 100%;
  height: 100vh;
  background-color: #ffffff;
  padding-bottom: 40px;
  margin-right: ${({ visible }) => (visible ? 0 : '-583px')};
  transition: 0.4s;
  word-break: break-all;
  overflow-y: auto;
  z-index: 4;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 50px 50px 30px 50px;
`;

const FlexBetween = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
`;

export {
  BackButton,
  BackButtonText,
  Container,
  DetailContainer,
  FlexBetween,
  Header,
  Image,
  ImageContainer,
  LinkContainer,
  MessageContainer,
  SkeletonContainer,
  TitleCampaignAside,
  TitleCampaignContainer,
};
