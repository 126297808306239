import { createContext, ReactElement, useEffect, useState } from 'react';
import axios from 'axios';
import {
  getAccessTokenPayload,
  legacyGetFromLS,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility';
import { ContextValue, ShowOnboardingResponse } from './types';
import { env } from '@/lib/env';

const OnboardingContext = createContext<ContextValue>(undefined as any);

const OnboardingProvider = (props: { children: ReactElement }) => {
  const [showAdminBanner, setShowAdminBanner] = useState<boolean | undefined>(
    false,
  );
  const [showAdminStepper, setShowAdminStepper] = useState<boolean | undefined>(
    false,
  );

  const { selectedCompany } = useSelectedCompany();
  const auth = legacyGetFromLS('authData');

  const getOnboardingData = async () => {
    const headers = {
      'content-type': 'application/json',
      Authorization: auth.idToken,
    };
    const accessToken = await getAccessTokenPayload();
    const employeeNanoId = accessToken?.employeeId;

    const endpointMap = {
      staging: 'https://dev.api.flashapp.com.br/c/company/graphql',
      production: 'https://api.flashapp.com.br/c/company/graphql',
      development: 'https://dev.api.flashapp.com.br/c/company/graphql',
      // development: "http://localhost:4949/company/graphql",
    };

    const query = {
      operationName: 'hrosOnboarding',
      query: `query hrosOnboarding($employeeNanoId: ID! $companyNanoId: ID!) {
        hrosOnboarding(employeeNanoId: $employeeNanoId companyNanoId: $companyNanoId ) {
          showOnboarding
          type
        }
      }`,
      variables: {
        employeeNanoId,
        companyNanoId: selectedCompany.id,
      },
    };

    const { data: response } = await axios<ShowOnboardingResponse>({
      url: endpointMap[env.STAGE],
      method: 'post',
      headers: headers,
      data: query,
    });
    const onboardingType = response.data?.hrosOnboarding?.type;

    if (onboardingType === 'ADMIN_BANNER') {
      setShowAdminBanner(response.data?.hrosOnboarding.showOnboarding);
    }
    if (onboardingType === 'ADMIN_ONBOARDING') {
      setShowAdminStepper(response.data?.hrosOnboarding.showOnboarding);
    }
  };

  useEffect(() => {
    getOnboardingData();
  }, []);

  return (
    <OnboardingContext.Provider
      value={{
        showAdminBanner,
        showAdminStepper,
        setShowAdminBanner,
        setShowAdminStepper,
      }}
    >
      {props.children}
    </OnboardingContext.Provider>
  );
};

export { OnboardingProvider, OnboardingContext };
