import {
  Dispatch,
  useEffect,
  SetStateAction,
  MutableRefObject,
  useCallback,
} from 'react';
import { LoaderContainer, StyledInboxMessageListWrapper } from './styles';
import { TabContentSkeleton } from '../TabContentSkeleton';
import { InboxMessageGroup } from '../InboxMessageGroup';
import { useGetInbox } from '@/hooks/use-get-inbox';
import {
  GroupedByTime,
  InboxMessage,
  InboxMessageWithGroup,
} from '../../types';
import { EmptyListIcon } from '../EmptyState/Icons/EmptyListIcon';
import { EmptyState } from '../EmptyState';
import { Loader } from '@flash-tecnologia/hros-web-ui-v2';
import { useAckAllInboxMessage } from '@/hooks/use-ack-all-inbox-messages';
import { getAccessTokenPayloadSync } from '@flash-tecnologia/hros-web-utility';

export const InboxMessageList = ({
  formattedInboxMessages,
  setFormattedInboxMessages,
  inboxResponseMetadata,
  query,
  opt: { invertTitle, uniqueList, showAckAll },
}: {
  formattedInboxMessages: Record<GroupedByTime, InboxMessageWithGroup[]>;
  setFormattedInboxMessages: Dispatch<
    SetStateAction<Record<GroupedByTime, InboxMessageWithGroup[]>>
  >;
  inboxResponseMetadata: MutableRefObject<{
    isLoading: boolean;
    metadata?: {
      totalCount: number;
      totalPages: number;
      currentPage: number;
      nextPage: number | null;
      prevPage: number | null;
    };
  }>;
  query: {
    page: number;
    limit: number;
  };
  opt: {
    invertTitle: boolean;
    uniqueList: boolean;
    showAckAll: boolean;
  };
}) => {
  const { mutate: ackAllInboxMessages } = useAckAllInboxMessage();
  const {
    isLoading: inboxIsLoading,
    parsedData: inboxResponse,
    data: dataRef,
  } = useGetInbox(query);

  const inboxMessagesTotalCount = inboxResponse?.metadata?.totalCount ?? 0;

  const alreadyOnList = (inboxMessage: InboxMessage) => {
    return (
      formattedInboxMessages.today.find(({ id }) => id === inboxMessage.id) ||
      formattedInboxMessages.yesterday.find(
        ({ id }) => id === inboxMessage.id,
      ) ||
      formattedInboxMessages.others.find(({ id }) => id === inboxMessage.id)
    );
  };
  const { employeeId } = getAccessTokenPayloadSync();

  const onClickAckAllMessages = () => {
    ackAllInboxMessages({
      assignedTo: employeeId,
    });

    setFormattedInboxMessages((oldValue) => {
      oldValue.today = oldValue.today.map((inboxMessage) => ({
        ...inboxMessage,
        acknowledged: true,
      }));
      oldValue.yesterday = oldValue.yesterday.map((inboxMessage) => ({
        ...inboxMessage,
        acknowledged: true,
      }));
      oldValue.others = oldValue.others.map((inboxMessage) => ({
        ...inboxMessage,
        acknowledged: true,
      }));
      return oldValue;
    });
  };

  const isEmpty =
    formattedInboxMessages.today.length === 0 &&
    formattedInboxMessages.yesterday.length === 0 &&
    formattedInboxMessages.others.length === 0;

  useEffect(() => {
    inboxResponseMetadata.current = {
      isLoading: inboxIsLoading,
      metadata: inboxResponse?.metadata,
    };

    if (
      inboxResponse?.records.today.length ||
      inboxResponse?.records.yesterday.length ||
      inboxResponse?.records.others.length
    ) {
      setFormattedInboxMessages((oldValue) => {
        const today = [
          ...oldValue.today,
          ...inboxResponse?.records.today.filter(
            (inboxMessage) => !alreadyOnList(inboxMessage),
          ),
        ];
        const yesterday = [
          ...oldValue.yesterday,
          ...inboxResponse?.records.yesterday.filter(
            (inboxMessage) => !alreadyOnList(inboxMessage),
          ),
        ];
        const others = [
          ...oldValue.others,
          ...inboxResponse?.records.others.filter(
            (inboxMessage) => !alreadyOnList(inboxMessage),
          ),
        ];
        return { today, yesterday, others };
      });
    }
  }, [dataRef]);

  useEffect(() => {
    inboxResponseMetadata.current.isLoading = inboxIsLoading;
  }, [inboxIsLoading]);

  if ((inboxIsLoading && isEmpty) || (isEmpty && inboxMessagesTotalCount > 0)) {
    return (
      <>
        <TabContentSkeleton length={4} height={74} />
      </>
    );
  }

  if (inboxMessagesTotalCount <= 0 && isEmpty) {
    return (
      <EmptyState
        title="Você ainda não possui mensagens ou avisos da Flash"
        subtitle="Suas mensagens e avisos diversos serão exibidos aqui."
        icon={<EmptyListIcon />}
      />
    );
  }

  return (
    <>
      <StyledInboxMessageListWrapper>
        {uniqueList ? (
          <InboxMessageGroup
            inboxMessages={[
              ...formattedInboxMessages.today,
              ...formattedInboxMessages.yesterday,
              ...formattedInboxMessages.others,
            ]}
            opt={{ invertTitle, showAckAll }}
          />
        ) : (
          <>
            {formattedInboxMessages.today.length ? (
              <InboxMessageGroup
                title={'Hoje'}
                inboxMessages={formattedInboxMessages.today}
                onClickAckAllMessages={onClickAckAllMessages}
                opt={{ invertTitle, showAckAll }}
              />
            ) : null}
            {formattedInboxMessages.yesterday.length ? (
              <InboxMessageGroup
                title={'Ontem'}
                inboxMessages={formattedInboxMessages.yesterday}
                onClickAckAllMessages={onClickAckAllMessages}
                opt={{
                  invertTitle,
                  showAckAll:
                    showAckAll && !formattedInboxMessages.today.length,
                }}
              />
            ) : null}
            {formattedInboxMessages.others.length ? (
              <InboxMessageGroup
                title={'Antigas'}
                inboxMessages={formattedInboxMessages.others}
                onClickAckAllMessages={onClickAckAllMessages}
                opt={{
                  invertTitle,
                  showAckAll:
                    showAckAll &&
                    !formattedInboxMessages.today.length &&
                    !formattedInboxMessages.yesterday.length,
                }}
              />
            ) : null}
          </>
        )}
        {inboxIsLoading && (
          <LoaderContainer>
            <Loader variant="primary" size="small" />
          </LoaderContainer>
        )}
      </StyledInboxMessageListWrapper>
    </>
  );
};
