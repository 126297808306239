import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > div:not(:first-child) {
    margin-left: -5px;
  }
`;

const IconContainer = styled.div<{ backgroundColor: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const TotalNumberContainer = styled(Typography)`
  margin-left: 5px;
  color: var(--color-neutral-dark4);
  font-weight: 400;
`;

export { Container, IconContainer, TotalNumberContainer };
