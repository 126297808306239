import styled from 'styled-components';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';

const Header = styled.header<{ alertMode?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  > :first-child {
    position: absolute;
    top: -40px;
    background-color: ${({ alertMode }) =>
      alertMode
        ? 'var(--color-feedback-error-light1)'
        : 'var(--color-secondary-light2)'};
    padding: 0 !important;
    height: 80px;
    width: 80px;
  }

  > :nth-child(2) {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
`;

const Title = styled(Typography)<{ alertMode?: boolean }>`
  color: ${({ alertMode }) =>
    alertMode ? 'var(--color-feedback-error-dark1)' : 'var(--color-primary)'};
  max-width: 390px;
  text-align: center;
  padding-top: 50px;
`;

const Body = styled.div`
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Message = styled(Typography)<{ alertMode?: boolean }>`
  color: var(--color-neutral-dark5);
  max-width: 390px;
  text-align: center;
`;

const PrimaryMessage = styled(Message)`
  margin-top: 10px;
  font-weight: 600 !important;
  max-width: 300px;
`;

const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;

  button {
    margin: 0 15px;
  }
`;

export { Body, Header, Title, TitleContainer, PrimaryMessage, Message, Footer };
