import styled from 'styled-components';

export const IconContainer = styled.div`
  background-color: var(--color-secondary-95);
  width: 40px;
  height: 40px;
  border-radius: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
`;

export const IconDot = styled.div`
  position: absolute;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: var(--color-primary);
  top: 2px;
  left: 2px;
`;
