export const EmptyListIcon = () => {
  return (
    <svg
      width="186"
      height="153"
      viewBox="0 0 186 153"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="40.1992"
        y="13.001"
        width="106.4"
        height="117.6"
        rx="14.0625"
        fill="#EBE6E9"
      />
      <g filter="url(#filter0_d_1848_73014)">
        <rect
          x="29"
          y="91.4004"
          width="98.7"
          height="28"
          rx="4.6875"
          fill="white"
        />
        <rect
          x="31.7998"
          y="94.2002"
          width="22.4"
          height="22.4"
          rx="7.03125"
          fill="#9F939B"
        />
        <path
          d="M43.6021 106.69V106.41C43.6021 104.674 46.2341 104.59 46.2341 102.448C46.2341 100.852 45.1001 100.082 43.3081 100.082C41.2081 100.082 39.7661 101.37 39.9481 103.148L41.2781 104.1C41.0681 102.392 41.9501 101.58 43.1961 101.58C44.1201 101.58 44.5401 101.986 44.5401 102.602C44.5401 104.016 42.1181 104.156 42.1181 106.284V106.69H43.6021ZM43.7421 109.602V107.614H41.9501V109.602H43.7421Z"
          fill="white"
        />
        <rect
          x="59.7998"
          y="98.4014"
          width="22.4"
          height="4.2"
          rx="2.1"
          fill="#D1C7CE"
        />
        <rect
          x="59.7998"
          y="108.201"
          width="35"
          height="4.2"
          rx="2.1"
          fill="#EBE6E9"
        />
      </g>
      <g filter="url(#filter1_d_1848_73014)">
        <rect
          x="59.0996"
          y="57.7998"
          width="98.7"
          height="28"
          rx="4.6875"
          fill="white"
        />
        <rect
          x="61.8994"
          y="60.6016"
          width="22.4"
          height="22.4"
          rx="7.03125"
          fill="#9F939B"
        />
        <path
          d="M73.7017 73.0914V72.8114C73.7017 71.0754 76.3337 70.9914 76.3337 68.8494C76.3337 67.2534 75.1997 66.4834 73.4077 66.4834C71.3077 66.4834 69.8657 67.7714 70.0477 69.5494L71.3777 70.5014C71.1677 68.7934 72.0497 67.9814 73.2957 67.9814C74.2197 67.9814 74.6397 68.3874 74.6397 69.0034C74.6397 70.4174 72.2177 70.5574 72.2177 72.6854V73.0914H73.7017ZM73.8417 76.0034V74.0154H72.0497V76.0034H73.8417Z"
          fill="white"
        />
        <rect
          x="89.8994"
          y="64.8008"
          width="22.4"
          height="4.2"
          rx="2.1"
          fill="#D1C7CE"
        />
        <rect
          x="89.8994"
          y="74.6006"
          width="35"
          height="4.2"
          rx="2.1"
          fill="#EBE6E9"
        />
      </g>
      <g filter="url(#filter2_d_1848_73014)">
        <rect
          x="29"
          y="24.1973"
          width="98.7"
          height="28"
          rx="4.6875"
          fill="white"
        />
        <rect
          x="31.7998"
          y="26.9971"
          width="22.4"
          height="22.4"
          rx="7.03125"
          fill="#9F939B"
        />
        <path
          d="M43.6021 39.4869V39.2069C43.6021 37.4709 46.2341 37.3869 46.2341 35.2449C46.2341 33.6489 45.1001 32.8789 43.3081 32.8789C41.2081 32.8789 39.7661 34.1669 39.9481 35.9449L41.2781 36.8969C41.0681 35.1889 41.9501 34.3769 43.1961 34.3769C44.1201 34.3769 44.5401 34.7829 44.5401 35.3989C44.5401 36.8129 42.1181 36.9529 42.1181 39.0809V39.4869H43.6021ZM43.7421 42.3989V40.4109H41.9501V42.3989H43.7421Z"
          fill="white"
        />
        <rect
          x="59.7998"
          y="31.1973"
          width="22.4"
          height="4.2"
          rx="2.1"
          fill="#D1C7CE"
        />
        <rect
          x="59.7998"
          y="40.9971"
          width="35"
          height="4.2"
          rx="2.1"
          fill="#EBE6E9"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1848_73014"
          x="0.875"
          y="67.9629"
          width="154.95"
          height="84.25"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4.6875" />
          <feGaussianBlur stdDeviation="14.0625" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1848_73014"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1848_73014"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_1848_73014"
          x="30.9746"
          y="34.3623"
          width="154.95"
          height="84.25"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4.6875" />
          <feGaussianBlur stdDeviation="14.0625" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1848_73014"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1848_73014"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_1848_73014"
          x="0.875"
          y="0.759766"
          width="154.95"
          height="84.25"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4.6875" />
          <feGaussianBlur stdDeviation="14.0625" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1848_73014"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1848_73014"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
