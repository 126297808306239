import {
  PageHeader as BasePageHeader,
  PageContainer as BasePageContainer,
} from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const MainPage = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.s};

  & > div:last-child {
    padding-bottom: ${({ theme }) => theme.spacings.m};
  }
`;

export const PageHeader = styled(BasePageHeader)`
  && {
    padding-bottom: ${({ theme }) => theme.spacings.xs4};
  }
`;

export const PageContainer = styled(BasePageContainer)`
  && {
    flex-grow: 0;
  }
`;
export const RowPageContainerWidgets = styled(PageContainer)`
  && {
    display: flex;
    flex-direction: row;
    flex: auto;
    gap: ${({ theme }) => theme.spacings.xs};
  }
`;

export const RowPageContainer = styled(PageContainer)`
  &.page-container-container {
    display: flex;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.spacings.xs};

    @media (width > 1090px) {
      min-height: 350px;
      height: 340px;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
`;

export const OnboardingTasksContainer = styled.div`
  height: 100%;
  width: 100%;
  max-width: 386px;

  @media (width <= 1090px) {
    max-height: 338px;
  }

  @media (width > 1090px) {
    min-width: 386px;
  }
`;
