import { setEventTracking, sanitizeHtml } from '@/utils';
import { Icons, dayjs } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from 'react-i18next';
import { NotificationProps } from '../../types';
import { Icon } from '../Icon';
import {
  Button,
  ButtonContainer,
  ContentContainer,
  Date,
  DateContainer,
  IconContainer,
  MainContainer,
  Space,
  TextWithHtml,
  Title,
} from './styles';

import { NotificationDetail } from '../NotificationDetail';
import { useNavigate } from 'react-router-dom';
import { useNotificationListRootContext } from '../..';

const Notification = ({
  id,
  title,
  date,
  type,
  message,
  visualized,
  responded,
  showNotificationDetails,
}: NotificationProps & {
  showNotificationDetails: boolean;
}) => {
  const navigate = useNavigate();
  const {
    notificationIdSelected,
    setNotificationIdSelected,
    detailVisible,
    setDetailVisible,
  } = useNotificationListRootContext();

  const [t] = useTranslation('translations', {
    keyPrefix: 'notification',
  });

  return (
    <MainContainer visualized={visualized}>
      {showNotificationDetails &&
        detailVisible &&
        notificationIdSelected == id && (
          <NotificationDetail
            id={notificationIdSelected}
            visible={detailVisible}
            onClickBackButton={() => setDetailVisible(!detailVisible)}
          />
        )}
      <IconContainer>
        <Icon
          name="IconWallet"
          color="var(--color-secondary-dark5)"
          fill="var(--color-secondary-light2)"
          backgroundColor="var(--color-secondary-light2)"
        />
      </IconContainer>
      <ContentContainer>
        <Title variant="body3">{title}</Title>
        <DateContainer>
          <Date variant="caption">{dayjs(date).format('DD/MM/YYYY')}</Date>
          <Space />
          <Date variant="caption">{dayjs(date).format('HH:mm')}</Date>
        </DateContainer>
        {message ? (
          <TextWithHtml
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(message) }}
            style={{ marginTop: '8px' }}
          />
        ) : null}

        {['research', 'enps']?.includes(type) ? (
          <>
            <ButtonContainer
              onClick={() => {
                setEventTracking(
                  'employee_engagement_companyfeed_answer_clicked',
                );
                navigate(`/engagement/survey/answer/0/${id}`);
              }}
            >
              <Button variant="caption">
                {responded
                  ? t('researchButtonLabelAnswered')
                  : t('researchButtonLabel')}
              </Button>
              <Icons
                name="IconArrowRight"
                size={15}
                color="var(--color-secondary-dark5)"
              />
            </ButtonContainer>
          </>
        ) : (
          <ButtonContainer
            onClick={() => {
              setEventTracking(
                'employee_engagement_companyfeed_viewdetails_clicked',
              );
              setNotificationIdSelected(id);
              setDetailVisible(!detailVisible);
            }}
          >
            <Button variant="caption">{t('detailButton')}</Button>
            <Icons
              name="IconArrowRight"
              size={15}
              color="var(--color-secondary-dark5)"
            />
          </ButtonContainer>
        )}
      </ContentContainer>
    </MainContainer>
  );
};

export { Notification };
