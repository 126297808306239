import {
  usePermissions,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility';
import { useFlag } from '@flash-tecnologia/feature-flags';
import { AlertBanner } from '@/pages/AdminHome/components/AlertBanner';

const AlertSection = () => {
  const permissions = usePermissions();
  const { selectedCompany } = useSelectedCompany();

  const isAdmin =
    permissions.isAdmin ||
    permissions.companies
      .find((company) => company.id === selectedCompany.id)
      ?.permissions?.includes('core_contract_products');

  const { enabled: alertBannerEnabled } = useFlag({
    flagName: 'FLASH_OS_HOME_ALERT_BANNER',
    variant: true,
  });

  if (isAdmin && alertBannerEnabled) {
    return <AlertBanner />;
  }

  return null;
};
export default AlertSection;
