import { setEventTracking } from '@/utils';
import { useEffect, useState } from 'react';
import { ReactButton, TotalReactions } from './components';
import { BorderContainer, MainContainer } from './styles';
import { ReactFormProps, ReactionType } from './types';
import { useReactNotification } from '@/hooks/use-react-notification';

const ReactForm = ({
  reaction,
  engagementId,
  totalLikes,
  reactionsCount: reactionsCountValues,
}: ReactFormProps) => {
  const [reactValue, setReactValue] = useState<ReactionType>(
    ReactionType.NOT_SELECTED,
  );
  const [totalReactions, setTotalReactions] = useState(0);
  const [reactionsCount, setReactionsCount] = useState<Array<number>>([]);

  const { mutateAsync: reactNotification } = useReactNotification();
  useEffect(() => {
    if (reaction) setReactValue(reaction);
  }, [reaction]);

  useEffect(() => {
    if (totalLikes) setTotalReactions(totalLikes);
  }, [totalLikes]);

  useEffect(() => {
    if (reactionsCountValues?.length) setReactionsCount(reactionsCountValues);
  }, [reactionsCountValues]);

  const getReactTrackByValue = (value: number) => {
    const trackings = [
      'employee_engagement_companyfeed_react_clicked',
      'employee_engagement_companyfeed_react_like_clicked',
      'employee_engagement_companyfeed_react_love_clicked',
      'employee_engagement_companyfeed_react_support_clicked',
      'employee_engagement_companyfeed_react_congratulations_clicked',
    ];

    return trackings[value] ? trackings[value] : trackings[0];
  };

  return (
    <MainContainer>
      {totalReactions ? (
        <TotalReactions
          total={totalReactions}
          reactionsCount={reactionsCount}
        />
      ) : null}
      <BorderContainer>
        <ReactButton
          onClick={async (value) => {
            setReactValue(value);

            setReactionsCount(
              reactionsCount?.map((count, i) => {
                if (i + 1 === value) return count + 1;
                if (reactValue === i + 1) return count - 1;
                return count;
              }),
            );

            if (reactValue === 0) setTotalReactions(totalReactions + 1);

            await reactNotification({ id: engagementId, like: value });

            setEventTracking(getReactTrackByValue(value));
          }}
          value={reactValue}
        />
      </BorderContainer>
    </MainContainer>
  );
};

export { ReactForm };
