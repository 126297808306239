import styled from 'styled-components';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const ReactContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  > div {
    margin: 5px 10px 5px 0;
  }

  div:first-child,
  div:last-child {
    margin-left: 10;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;
`;

const React = styled.div<{ selected?: boolean; onClickMode?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background-color: var(--color-neutral-light1);
  border: 1px solid var(--color-neutral-light2);
  border-radius: 4px;

  :hover {
    background-color: ${({ onClickMode, selected }) =>
      onClickMode && !selected ? 'var(--color-neutral-light2)' : undefined};
    border-color: ${({ onClickMode, selected }) =>
      onClickMode && !selected ? 'var(--color-neutral-light3)' : undefined};

    * {
      fill: ${({ onClickMode, selected }) =>
        onClickMode && !selected ? 'var(--color-neutral-light2)' : undefined};
    }
  }

  cursor: ${({ onClickMode }) => (onClickMode ? 'pointer' : undefined)};

  background-color: ${({ selected }) =>
    selected ? 'var(--color-secondary-light1)' : undefined};
  border-color: ${({ selected }) =>
    selected ? 'var(--color-primary)' : undefined};

  * {
    /* stroke: var(--color-primary); */
    color: ${({ selected }) => (selected ? 'var(--color-primary)' : undefined)};
    fill: ${({ selected }) =>
      selected ? 'var(--color-secondary-light1)' : undefined};
  }
`;

const Label = styled(Typography)`
  color: var(--color-neutral-dark4);
  font-weight: 600;
`;

const Number = styled(Typography)<{ selected?: boolean }>`
  color: ${({ selected }) =>
    selected ? 'var(--color-primary)' : 'var(--color-neutral-light5)'};
  font-weight: 700;
`;

export { MainContainer, ReactContainer, LabelContainer, React, Label, Number };
