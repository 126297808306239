import { Skeleton as SkeletonUi } from '@mui/material';
import { Container } from './styles';

const Skeleton = () => (
  <Container>
    {[1, 2, 3, 4, 6, 7, 8]?.map((index) => (
      <SkeletonUi variant="rounded" key={index} />
    ))}
  </Container>
);

export { Skeleton };
