import styled from 'styled-components';
import { backgroundColors } from './reactions';
import { ReactIconProps } from './types';

const IconContainer = styled.div<{ type: ReactIconProps['type'] }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  cursor: pointer;
  background-color: ${({ type }) => backgroundColors[type]};
  transition: 0.1s;

  * {
    svg,
    path {
      transition: 0.1s;
    }
  }
`;

const MainContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  transition: 0.1s;

  :hover {
    padding-bottom: 25px;

    > div {
      height: 72px;
      width: 72px;

      * {
        svg,
        path {
          height: 38px;
          width: 38px;
        }
      }
    }
  }
`;

export { IconContainer, MainContainer };
