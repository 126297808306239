import { ReactNode, useCallback, useState } from 'react';
import {
  StyledContent,
  StyledTab,
  StyledTabIndicator,
  StyledTabs,
  StyledTabsContainer,
  StyledTabsWrapper,
} from './style';

type TabsProps = {
  tabs: {
    title: ReactNode;
    content: ReactNode;
  }[];
  onTabChange?: (index: number) => void;
};

export const Tabs = ({ tabs, onTabChange }: TabsProps) => {
  const [active, setActive] = useState(0);
  const [tabSizes, setTabSizes] = useState<
    Array<{ offsetLeft: number; offsetWidth: number }>
  >([]);

  const measuredRef = useCallback(
    (node: HTMLHeadingElement | null, index: number) => {
      if (node !== null) {
        setTabSizes((oldValue) => {
          oldValue[index] = {
            offsetLeft: node.offsetLeft,
            offsetWidth: node.offsetWidth,
          };
          return oldValue;
        });
      }
    },
    [],
  );

  return (
    <StyledTabsWrapper>
      <StyledTabsContainer>
        <StyledTabs>
          {tabs.map((tab, index) => (
            <StyledTab
              ref={(elem) => measuredRef(elem, index)}
              active={index === active}
              key={index}
              onClick={() => {
                setActive(index);
                onTabChange && onTabChange(index);
              }}
            >
              {tab.title}
            </StyledTab>
          ))}
        </StyledTabs>
        <StyledTabIndicator activeIndex={active} tabSizes={tabSizes} />
      </StyledTabsContainer>
      <StyledContent>{tabs[active].content}</StyledContent>
    </StyledTabsWrapper>
  );
};
