import { trpc } from '@api/client';

export const useGetNotificationDetails = (input: {
  id: string;
  selectedCompanyId: string;
}) => {
  const { isLoading, data } = trpc.getNotificationDetails.useQuery(input, {
    refetchOnWindowFocus: false,
  });

  return {
    data,
    isLoading,
  };
};
