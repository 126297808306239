import styled from 'styled-components';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';

const IconsContainer = styled.div`
  position: absolute;
  top: -75px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 10px;
  border-radius: 12px;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.16);
  width: 260px;
  max-height: 70px !important;

  div:not(:last-child) {
    margin-right: 8px;
  }
`;

const ButtonContainer = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

const Label = styled(Typography)<{ color?: string }>`
  color: ${({ color = 'var(--color-neutral-dark5)' }) => color};
  font-weight: 600;
  margin-left: 5px;
`;

export { IconsContainer, ButtonContainer, Label };
