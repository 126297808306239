import { TotalReactionsProps } from './components/TotalReactions/types';

interface ReactFormProps {
  reaction: number;
  engagementId: string;
  totalLikes: number;
  reactionsCount: TotalReactionsProps['reactionsCount'];
}

export enum ReactionType {
  NOT_SELECTED,
  LIKE,
  HEART,
  SUPPORT,
  CONGRATS,
}

export { ReactFormProps };
