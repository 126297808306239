import { EmptyListIcon } from './Icons/EmptyListIcon';
import { Container, Title, Text } from './styles';
import { EmptyMessageProps } from './types';

const EmptyMessage = ({ title, message }: EmptyMessageProps) => (
  <Container>
    <EmptyListIcon />
    <Title variant="headline7">{title}</Title>
    <Text variant="body3">{message}</Text>
  </Container>
);

export { EmptyMessage };
