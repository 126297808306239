import styled from 'styled-components';

export const StyledListContent = styled.div<{
  listStyle: {
    spacing_x: number;
    spacing_y: number;
    gap: number;
    padding_right: number;
    padding_left: number;
  };
}>`
  display: flex;
  width: calc(100% - ${({ listStyle }) => listStyle.spacing_x * 2}px);
  height: calc(100% - ${({ listStyle }) => listStyle.spacing_y}px);
  position: absolute;
  top: ${({ listStyle }) => listStyle.spacing_y}px;
  flex-direction: column;
  gap: ${({ listStyle }) => listStyle.gap}px;
  overflow-y: auto;
  padding-right: ${({ listStyle }) => listStyle.padding_right}px;
  padding-left: ${({ listStyle }) => listStyle.padding_left}px;
  box-sizing: border-box;
`;

export const StyledTabContent = styled.div<{ bgColor: string }>`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0px 24px 0px;
  border-bottom-left-radius: ${({ theme }) => theme.borders.radius.s};
  border-bottom-right-radius: ${({ theme }) => theme.borders.radius.s};
  background-color: ${({ bgColor }) => bgColor};
`;
