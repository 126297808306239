import styled from 'styled-components';

export const StyledTabs = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const StyledTabsContainer = styled.div`
  position: relative;
`;

export const StyledTab = styled.div<{ active?: boolean }>`
  flex: none;
  cursor: pointer;
  transition: color 0.3s ease;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ active, theme }) =>
    active ? theme.colors.secondary[50] : theme.colors.neutral[50]};
  font-weight: ${({ active }) => (active ? 700 : 600)};
  font-size: 14px;
  filter: ${({ active }) => (active ? 'inherit' : 'invert(1) grayscale(1)')};
  opacity: ${({ active }) => (active ? 'inherit' : '0.7')};
`;

export const StyledTabIndicator = styled.div<{
  activeIndex: number;
  tabSizes: { offsetLeft: number; offsetWidth: number }[];
}>`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.secondary[50]};
  transform: translateX(
    ${({ activeIndex, tabSizes }) => tabSizes[activeIndex]?.offsetLeft ?? 0}px
  );
  transition: transform 0.3s ease, width 0.3s ease;
  max-width: ${({ activeIndex, tabSizes }) =>
    tabSizes[activeIndex]?.offsetWidth ?? 0}px;
  width: 100%;
`;

export const StyledTabsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledContent = styled.div`
  flex: 1;
`;
