import { trpc } from '@api/client';

export const useClickNotification = () => {
  const { mutateAsync, isLoading } = trpc.clickNotification.useMutation();

  return {
    mutateAsync,
    isLoading,
  };
};
