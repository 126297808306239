import { MODAL_STEPS, ModalStep } from './steps';

type FooterConfig = {
  nextText: string;
  backText?: string;
  showIcon: boolean;
  closeable?: boolean;
};

export const getFooterConfigForModal = (
  modalStep: number,
  tourStep: number,
  onboardingTourSteps: ModalStep[],
  isAdminStepper: boolean,
): FooterConfig => {
  if (modalStep === MODAL_STEPS.WELCOME)
    return {
      backText: 'Pular',
      nextText: 'Conhecer a plataforma',
      showIcon: true,
    };

  if (modalStep === MODAL_STEPS.ONBOARDING_TOUR) {
    const isLastTourStep = tourStep === onboardingTourSteps.length - 1;
    return {
      backText: 'Voltar',
      nextText: isAdminStepper || !isLastTourStep ? 'Próximo' : 'Entendi!',
      showIcon: false,
    };
  }

  if (modalStep === MODAL_STEPS.FINISH && isAdminStepper) {
    return {
      nextText: 'Ir para pedidos de benefício',
      showIcon: true,
      closeable: true,
    };
  }

  return {
    nextText: 'Entendi!',
    showIcon: false,
  };
};
