import { trpc } from '@api/client';

export const useReactNotification = () => {
  const { mutateAsync, isLoading } = trpc.reactNotification.useMutation();

  return {
    mutateAsync,
    isLoading,
  };
};
