import { useEffect, useState, useContext } from 'react';
import {
  AuthenticatedUser,
  getAuthenticatedUser,
  setInSS,
} from '@flash-tecnologia/hros-web-utility';
import { Modal, dayjs } from '@flash-tecnologia/hros-web-ui-v2';
import { resendEmail } from '@api/api';
import { Context } from '@context/configuration';

import { ConfirmEmailStep } from './components/ConfirmEmailStep';
import { FluxEndStep } from './components/FluxEndStep';

export const EmailConfirmModal = () => {
  const { dispatch } = useContext(Context);
  const [userAuth, setUserAuth] = useState<AuthenticatedUser>();
  const [modalStep, setModalStep] = useState<'ConfirmEmailModal' | 'FluxEnd'>(
    'ConfirmEmailModal',
  );
  const [modalStatus, setModalStatus] = useState<'open' | 'onHold' | 'closed'>(
    'closed',
  );
  const isModalOpen = modalStatus === 'open';

  const sendCode = () => {
    resendEmail({ dispatch: dispatch as any });
    setInSS({
      key: 'lastAttemptError',
      value: dayjs(),
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (userAuth?.email && userAuth?.emailVerified) {
        clearInterval(intervalId);
        return;
      }
      //@ts-ignore
      if (
        userAuth?.email &&
        !userAuth?.emailVerified &&
        //@ts-ignore
        window?.pendo?.guides
      ) {
        //set modal on hold if pendo guide is open and modal is open
        if (
          //@ts-ignore
          window?.pendo?.isGuideShown?.() &&
          modalStatus === 'open'
        ) {
          setModalStatus('onHold');
          return;
        }
        //set modal open if pendo guide is not open and modal is on hold
        if (
          //@ts-ignore
          !window?.pendo?.isGuideShown?.() &&
          modalStatus === 'onHold'
        ) {
          setModalStatus('open');
        }
      }
    }, 500);

    return () => clearInterval(intervalId);
  }, [modalStatus, userAuth]);

  useEffect(() => {
    async function getAuthUser() {
      const authenticatedUser = await getAuthenticatedUser();
      if (authenticatedUser?.email && !authenticatedUser.emailVerified) {
        sendCode();
        setModalStatus('open');
      }

      setUserAuth(authenticatedUser);
    }

    getAuthUser();
  }, []);

  const renderContent = () => {
    switch (modalStep) {
      case 'ConfirmEmailModal':
        return (
          <>
            <Modal.Header
              title="Confirme seu endereço de e-mail"
              description="Mantenha a sua conta segura confirmando o e-mail informado."
            />
            <Modal.Content>
              <ConfirmEmailStep
                authenticatedUser={userAuth as AuthenticatedUser}
                onSubmit={() => setModalStep('FluxEnd')}
                onClose={() => setModalStatus('closed')}
              />
            </Modal.Content>
          </>
        );
      case 'FluxEnd':
        return (
          <Modal.Content>
            <FluxEndStep
              username={userAuth?.name}
              onClose={() => setModalStatus('closed')}
            />
          </Modal.Content>
        );
    }
  };

  return (
    <Modal.Root
      open={isModalOpen}
      onClose={() => setModalStatus('closed')}
      size={modalStep === 'FluxEnd' ? 'xs' : 'sm'}
    >
      {renderContent()}
    </Modal.Root>
  );
};
