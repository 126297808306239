import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { Notification } from './components/Notification';
import { NotificationListProps } from './types';
import { Skeleton } from './components/Skeleton';
import { EmptyMessage } from './components';
import { useTranslation } from 'react-i18next';
import { LoaderContainer } from './styled';
import { Loader } from '@flash-tecnologia/hros-web-ui-v2';

type NotificationListRootData = {
  notificationIdSelected: string;
  setNotificationIdSelected: Dispatch<SetStateAction<string>>;
  detailVisible: boolean;
  setDetailVisible: Dispatch<SetStateAction<boolean>>;
};

const NotificationListRootContext = createContext<NotificationListRootData>(
  {} as NotificationListRootData,
);

export const NotificationList = ({
  isLoading,
  notifications,
  onSubmit,
  opt: { showNotificationDetails },
}: NotificationListProps) => {
  const [notificationIdSelected, setNotificationIdSelected] = useState('');
  const [detailVisible, setDetailVisible] = useState<boolean>(false);
  const [t] = useTranslation('translations', {
    keyPrefix: 'notification',
  });
  return (
    <NotificationListRootContext.Provider
      value={{
        notificationIdSelected,
        setNotificationIdSelected,
        detailVisible,
        setDetailVisible,
      }}
    >
      {isLoading && !notifications.length ? (
        <Skeleton />
      ) : notifications.length ? (
        notifications?.map((notification, i) => (
          <Notification
            key={'header-notification-' + i}
            {...notification}
            showNotificationDetails={showNotificationDetails}
            onSubmit={onSubmit}
          />
        ))
      ) : (
        <EmptyMessage
          title={t('emptyMessageTitle')}
          message={t('emptyMessageText')}
        />
      )}
      {isLoading && notifications.length ? (
        <LoaderContainer>
          <Loader variant="primary" size="small" />
        </LoaderContainer>
      ) : null}
    </NotificationListRootContext.Provider>
  );
};

export const useNotificationListRootContext = () => {
  const context = useContext(NotificationListRootContext);

  if (!context) {
    throw new Error(
      'useNotificationListRootContext must be used within a RootProvider',
    );
  }

  return context;
};
