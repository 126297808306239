import {
  StyledAckAll,
  StyledAckAllContent,
  StyledInboxGroupContent,
  StyledTitle,
  StyledTitleContent,
} from './styles';
import { InboxMessageWithGroup } from '../../types';
import { InboxMessage } from '../InboxMessage';
import { Icons } from '@flash-tecnologia/hros-web-ui-v2';

type InboxMessageGroupProps = {
  title?: string;
  inboxMessages: InboxMessageWithGroup[];
  onClickAckAllMessages?: () => void;
  opt: {
    invertTitle: boolean;
    showAckAll: boolean;
  };
};

export const InboxMessageGroup = ({
  title,
  inboxMessages,
  onClickAckAllMessages,
  opt,
}: InboxMessageGroupProps) => {
  return (
    <StyledInboxGroupContent>
      {title || opt.showAckAll ? (
        <StyledTitleContent>
          {title && <StyledTitle variant={'display1'}>{title}</StyledTitle>}
          {opt.showAckAll && (
            <StyledAckAllContent
              onClick={() => onClickAckAllMessages && onClickAckAllMessages()}
            >
              <StyledAckAll variant={'caption'}>
                Marcar todas como lidas
              </StyledAckAll>
              <Icons
                name={'IconCheck'}
                height={16}
                color={'var(--color-neutral-50)'}
                strokeWidth="2px"
                width={16}
              />
            </StyledAckAllContent>
          )}
        </StyledTitleContent>
      ) : null}
      {inboxMessages.map((inboxMessage) => (
        <InboxMessage
          inboxMessage={inboxMessage}
          key={inboxMessage.id}
          opt={{ invertTitle: opt.invertTitle }}
        />
      ))}
    </StyledInboxGroupContent>
  );
};
