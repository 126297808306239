import { useMemo, useState, useEffect, useContext } from 'react';
import {
  AuthenticatedUser,
  getFromSS,
  removeFromSS,
  setInSS,
} from '@flash-tecnologia/hros-web-utility';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { ButtonsContainer, FlexContainer } from './styles';

import {
  Button,
  Icons,
  LinkButton,
  OTPField,
  Typography,
  dayjs,
} from '@flash-tecnologia/hros-web-ui-v2';

import { Countdown } from '../Countdown';
import { setEventTracking } from '@utils';
import { Context } from '@context/configuration';
import { resendEmail, validateCode } from '@api/api';
import SectionLoading from '@components/SectionLoading';

const validationSchema = yup.object({
  otpValue: yup
    .string()
    .min(6, 'O código tem 6 números')
    .required('Este campo deve ser preenchido'),
});

interface StepProps {
  authenticatedUser: AuthenticatedUser;
  onSubmit?: () => any;
  toChangeEmail?: () => any;
  onClose?: () => any;
}

export const ConfirmEmailStep = ({
  authenticatedUser,
  onSubmit,
  toChangeEmail,
  onClose,
}: StepProps) => {
  const { dispatch } = useContext(Context);
  const lastAttemptError = useMemo(() => getFromSS('lastAttemptError'), []);
  const [timer, setTimer] = useState(60);
  const [awaitBlock, setAwaitBlock] = useState(true);
  const redactedEmail =
    authenticatedUser?.email?.replace(
      /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{5}/,
      '*****',
    ) || '';

  const formik = useFormik({
    initialValues: { otpValue: '' },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setEventTracking('home_verify_email_modal_confirm_clicked');

      await validateCode({
        attr: 'email',
        code: values.otpValue,
        dispatch: dispatch as any,
        callback: onSubmit,
      });
    },
  });

  const otpError = Boolean(formik.touched.otpValue && formik.errors.otpValue);

  const keepBlockResend = (lastAttempt: Date) => {
    const now = dayjs();
    const dateAttempt = dayjs(lastAttempt).add(60, 'second');

    if (dateAttempt.isAfter(now))
      setTimer(dayjs(dateAttempt).diff(now, 'seconds'));

    return dateAttempt.isAfter(now);
  };

  const sendCode = () => {
    setAwaitBlock(true);
    resendEmail({ dispatch: dispatch as any });
    setInSS({
      key: 'lastAttemptError',
      value: dayjs(),
    });
  };

  const resendCode = () => {
    setEventTracking('home_verify_email_modal_resend_code_clicked');
    sendCode();
  };

  const onCancel = () => {
    setEventTracking('home_verify_email_modal_cancel_clicked');
    onClose?.();
  };

  useEffect(() => {
    setEventTracking('home_verify_email_modal_opened');
    if (!lastAttemptError) return setAwaitBlock(false);
    if (keepBlockResend(lastAttemptError)) setAwaitBlock(true);
    else removeFromSS({ key: 'lastAttemptError' });
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <FlexContainer style={{ gap: 40 }}>
        <FlexContainer>
          <Typography variant="body3" variantColor="var(--color-neutral-40)">
            Insira o código que enviamos agora para <b>{redactedEmail}</b>
          </Typography>
          <OTPField
            name="otpValue"
            numInputs={6}
            value={formik.values.otpValue}
            onChange={(e: any) =>
              formik.handleChange({ target: { id: 'otpValue', value: e } })
            }
            error={otpError}
          />
          {otpError && (
            <Typography
              variant="body4"
              variantColor="var(--color-neutral-50)"
              style={{ display: 'flex', gap: 4 }}
            >
              <Icons
                name="IconAlertCircle"
                style={{ alignSelf: 'center', color: '#FEA034' }}
                size={14}
              />
              {formik.touched.otpValue && formik.errors.otpValue}
            </Typography>
          )}
        </FlexContainer>
        <FlexContainer>
          <FlexContainer>
            {!awaitBlock && <div style={{ marginBottom: '22px' }} />}
            {awaitBlock && (
              <Typography
                variant="body3"
                variantColor="var(--color-neutral-20)"
              >
                Aguarde o recebimento •{' '}
                <Countdown
                  time={timer}
                  onFinished={() => setAwaitBlock(false)}
                />
              </Typography>
            )}
            <FlexContainer
              style={{ flexDirection: 'row', alignItems: 'center' }}
            >
              <Typography
                variant="body3"
                variantColor="var(--color-neutral-20)"
              >
                Não recebeu seu código?
              </Typography>
              <LinkButton
                variant="primary"
                onClick={resendCode}
                disabled={awaitBlock}
              >
                Reenviar e-mail
                <Icons name="IconArrowBack" fill="none" />
              </LinkButton>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      <ButtonsContainer>
        <LinkButton
          variant="primary"
          style={{ alignSelf: 'center' }}
          onClick={onCancel}
        >
          Cancelar
        </LinkButton>
        <SectionLoading url="validateCode">
          {({ loading }) => (
            <Button
              variant="primary"
              size="large"
              disabled={loading}
              loading={loading}
              style={{ alignSelf: 'center', width: '198px' }}
              type="submit"
            >
              Confirmar
              <Icons name="IconArrowRight" />
            </Button>
          )}
        </SectionLoading>
      </ButtonsContainer>
    </form>
  );
};
